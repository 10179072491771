// load bootstrap 5.0, fontawesome 5.15.4 and google fonts


// popper + bootstrap (https://getbootstrap.com/docs/5.0/getting-started/introduction/#js)
// import '@popperjs/core/dist/umd/popper.min' // required by bootstrap
// import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/js/bootstrap.bundle.min'

// if (document.readyState === "complete" || document.readyState === "loaded") {
//      // document is already ready to go
// }
// document.addEventListener("DOMContentLoaded",function(){
//   console.log('Dom loaded')
// })
// load block loader
import '../features/loader'

// Ignore the warnings. It's an alias to the domain set by webpack.
import 'domainScript' // import domain script

import './style.scss'


